<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Assets</h3>
    <AssetsMain v-model="form.assets_main" type="estate_planning" ref="assetsMain" @refresh="refreshAssets" />
    <AssetsOverYN v-model="form.assets_other_high_value" :assets="form.assets_other" />
    <div v-if="form.assets_other_high_value" class="question-box with-arrow">
      <AssetsOther v-model="form.assets_other" type="estate_planning" ref="assetsOther" @refresh="refreshAssets" />
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import AssetsOther from "../../question/questions/clientProfile/assets/AssetsOther";
import AssetsOverYN from "../../question/questions/clientProfile/assets/AssetsOverYN";
import AssetsMain from "../../question/questions/clientProfile/assets/AssetsMain";

export default {
  name: 'AssetsEstatePlanning',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AssetsMain,
    AssetsOverYN,
    AssetsOther

  },
  data() {
    return {
      label: 'Assets' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refreshAssets () {
      if ('assetsMain' in this.$refs) this.$refs.assetsMain.get()
      if ('assetsOther' in this.$refs) this.$refs.assetsOther.get()
    }
  }
};
</script>
